import { handleErrorAxios } from "@/common/lib/formatter";
import Http from "@/common/lib/http";

const actions = {
    async listWarehousesPaginated({ commit, dispatch }, { complete, page }) {
        try {
            const { data } = await Http.instance.get("/bo/warehouses", {
                params: { complete, page }
            });
            return data;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_WAREHOUSES", parseError);
            throw parseError;
        }
    },
    async getWarehouseById({ commit, dispatch }, { id }) {
        try {
            const { data } = await Http.instance.get(`/bo/warehouses/${id}`);
            commit(
                "commons/UPDATE_MERCHANTS_GLOBAL",
                { ...data, merchantId: id, merchantType: "warehouse" },
                { root: true }
            );
            return data;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_WAREHOUSES", parseError);
            throw parseError;
        }
    },
    async createWarehouse(
        { commit, dispatch },
        {
            address,
            addressComplement,
            cityCode,
            communicationConfig,
            coordinates,
            description,
            images,
            keywords,
            name,
            neighborhood,
            phone,
            shortDescription,
            slug,
            supplierId
        }
    ) {
        try {
            const { data } = await Http.instance.post("/bo/warehouses", {
                supplierId,
                name,
                phone,
                slug,
                images,
                address,
                coordinates,
                cityCode,
                keywords,
                description,
                neighborhood,
                shortDescription,
                addressComplement,
                communicationConfig
            });
            return data;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_WAREHOUSES", parseError);
            throw parseError;
        }
    },
    async updateWarehouse(
        { commit, dispatch },
        {
            address,
            addressComplement,
            cityCode,
            communicationConfig,
            coordinates,
            description,
            id,
            images,
            keywords,
            name,
            neighborhood,
            phone,
            shortDescription,
            slug
        }
    ) {
        try {
            const { data } = await Http.instance.patch("/bo/warehouses", {
                id,
                name,
                phone,
                slug,
                images,
                address,
                coordinates,
                cityCode,
                keywords,
                description,
                communicationConfig,
                neighborhood,
                shortDescription,
                addressComplement
            });

            return data;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_WAREHOUSES", parseError);
            throw parseError;
        }
    },
    async updateWarehouseMoney({ commit, dispatch }, { id, moneyConfig }) {
        try {
            const { data } = await Http.instance.put(
                `/bo/warehouses/${id}/money`,
                {
                    moneyConfig
                }
            );
            commit(
                "commons/UPDATE_MERCHANTS_GLOBAL",
                { merchantId: id, merchantType: "warehouse", moneyConfig },
                { root: true }
            );
            return data;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_WAREHOUSES", parseError);
            throw parseError;
        }
    },
    async updateWarehouseServices({ commit, dispatch }, { id, services }) {
        try {
            const { data } = await Http.instance.put(
                `/bo/warehouses/${id}/services`,
                {
                    services
                }
            );
            commit(
                "commons/UPDATE_MERCHANTS_GLOBAL",
                { merchantId: id, merchantType: "warehouse", services },
                { root: true }
            );
            return data;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_WAREHOUSES", parseError);
            throw parseError;
        }
    },
    async setWarehouseActive(
        { commit, dispatch },
        { enabledForDeliveries, id, weight }
    ) {
        try {
            const { data } = await Http.instance.put(
                `/bo/warehouses/${id}/active`,
                {
                    enabledForDeliveries,
                    weight
                }
            );
            commit(
                "commons/UPDATE_MERCHANTS_GLOBAL",
                {
                    merchantId: id,
                    merchantType: "warehouse",
                    enabledForDeliveries,
                    weight
                },
                { root: true }
            );
            return data;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_WAREHOUSES", parseError);
            throw parseError;
        }
    },
    async transferWarehouse({ commit, dispatch }, { id, isOwn }) {
        try {
            const { data } = await Http.instance.put(
                `/bo/warehouses/${id}/transfer`,
                {
                    isOwn
                }
            );
            return data;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_WAREHOUSES", parseError);
            throw parseError;
        }
    },
    async deleteWarehouse({ commit, dispatch }, { definitely = false, id }) {
        try {
            await Http.instance.delete(
                `/bo/warehouses/${id}?definitely=${definitely}`
            );
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_WAREHOUSES", parseError);
            throw parseError;
        }
    }
};

export default actions;
