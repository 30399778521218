import { handleErrorAxios } from "@/common/lib/formatter";
import Http from "@/common/lib/http";

const actions = {
    async verifyMassiveInventories(
        { commit, dispatch },
        { merchantId, merchantType, rows }
    ) {
        try {
            const { data } = await Http.instance.post(
                "/bo/inventories/massive/verify",
                {
                    rows,
                    merchantId,
                    merchantType
                }
            );
            return data;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_INVENTORIES", parseError);
            throw parseError;
        }
    },
    async listMassiveInventories(
        { commit, dispatch },
        { merchantId, merchantType }
    ) {
        try {
            const { data } = await Http.instance.get(
                `/bo/inventories/massive/${merchantType}/${merchantId}`
            );
            return data;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_INVENTORIES", parseError);
            throw parseError;
        }
    },
    async saveMassiveInventories(
        { commit, dispatch },
        { inventories, merchantId, merchantType, overwrite }
    ) {
        try {
            const { data } = await Http.instance.post(
                "/bo/inventories/massive/save",
                {
                    overwrite,
                    inventories,
                    merchantId,
                    merchantType
                }
            );
            return data;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_INVENTORIES", parseError);
            throw parseError;
        }
    },
    async getInventoryByBarcode(
        { commit, dispatch },
        { barcode, merchantId, merchantType }
    ) {
        try {
            const { data } = await Http.instance.get("/bo/inventories/all/", {
                params: {
                    barcode,
                    merchantType,
                    merchantId
                }
            });
            return data.items;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_INVENTORIES", parseError);
            throw parseError;
        }
    },
    async searchInventories(
        { commit, dispatch },
        { barcode, brandSlug, keyword, lineSlug, merchantId, merchantType }
    ) {
        try {
            const { data } = await Http.instance.get(
                "/bo/inventories/search/",
                {
                    params: {
                        keyword,
                        barcode,
                        lineSlug,
                        brandSlug,
                        merchantId,
                        merchantType
                    }
                }
            );

            return data.items;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_INVENTORIES", parseError);
            throw parseError;
        }
    },
    async listAllInventories(
        { commit, dispatch },
        { brandSlug, keyword, lineSlug, merchantId, merchantType }
    ) {
        try {
            const { data } = await Http.instance.get("/bo/inventories/all", {
                params: {
                    lineSlug,
                    brandSlug,
                    keyword,
                    merchantType,
                    merchantId
                }
            });

            return data.items;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_INVENTORIES", parseError);
            throw parseError;
        }
    },
    async syncPricesInventory(
        { commit, dispatch },
        { merchantId, merchantType }
    ) {
        try {
            const { data } = await Http.instance.post(
                "/bo/inventories/sync/prices",
                {
                    merchantId,
                    merchantType
                }
            );

            return data;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_INVENTORIES", parseError);
            throw parseError;
        }
    },
    async disableAllInventories(
        { commit, dispatch },
        { merchantId, merchantType }
    ) {
        try {
            const { data } = await Http.instance.post(
                "/bo/inventories/active/all",
                {
                    merchantId,
                    merchantType
                }
            );
            return data;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_INVENTORIES", parseError);
            throw parseError;
        }
    },
    async activeAllInventories(
        { commit, dispatch },
        { merchantId, merchantType }
    ) {
        try {
            const { data } = await Http.instance.post(
                "/bo/inventories/active/all",
                {
                    merchantId,
                    merchantType
                }
            );
            return data;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_INVENTORIES", parseError);
            throw parseError;
        }
    },
    async getInventory(
        { commit, dispatch },
        { merchantId, merchantType, referenceId }
    ) {
        try {
            const { data } = await Http.instance.get(
                `/bo/inventories/${merchantType}/${merchantId}/${referenceId}`
            );
            return data;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_INVENTORIES", parseError);
            throw parseError;
        }
    },

    async createManyInventories(
        { commit, dispatch },
        { cashOutput, merchantId, merchantType, references }
    ) {
        try {
            const { data } = await Http.instance.post("/bo/inventories/many", {
                merchantId,
                merchantType,
                cashOutput,
                references
            });

            return data;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_INVENTORIES", parseError);
            throw parseError;
        }
    },
    async createInventory({ commit, dispatch }, inventory) {
        try {
            const { data } = await Http.instance.post(
                "/bo/inventories",
                inventory
            );

            return data;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_INVENTORIES", parseError);
            throw parseError;
        }
    },
    async updateInventory(
        { commit, dispatch },
        {
            alwaysStockMerchant,
            costMerchant,
            maxStockMerchant,
            merchantId,
            merchantType,
            minStockMerchant,
            priceMerchant,
            priceOfferMerchant,
            referenceId,
            stockMerchant
        }
    ) {
        try {
            const { data } = await Http.instance.patch("/bo/inventories", {
                merchantId,
                merchantType,
                referenceId,
                stockMerchant,
                maxStockMerchant,
                minStockMerchant,
                priceOfferMerchant,
                costMerchant,
                priceMerchant,
                alwaysStockMerchant
            });

            return data;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_INVENTORIES", parseError);
            throw parseError;
        }
    },
    async listInputsInventory(
        { commit, dispatch },
        { merchantId, merchantType, query }
    ) {
        try {
            const { data } = await Http.instance.get(
                `/bo/inventories/inputs/${merchantType}/${merchantId}`,
                {
                    params: query
                }
            );

            return data;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_INVENTORIES", parseError);
            throw parseError;
        }
    },
    async listReferencesInInventory(
        { commit, dispatch },
        {
            barcode,
            brandSlug,
            complete,
            keyword,
            lineSlug,
            merchantId,
            merchantType,
            page
        }
    ) {
        try {
            const { data } = await Http.instance.get("/bo/inventories", {
                params: {
                    barcode,
                    brandSlug,
                    complete,
                    keyword,
                    lineSlug,
                    merchantId,
                    merchantType,
                    page
                }
            });
            return data;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_INVENTORIES", parseError);
            throw parseError;
        }
    },
    async updateInputsInventoryByStore(
        { commit, dispatch },
        { costNew, id, merchantId, merchantType }
    ) {
        try {
            const { data } = await Http.instance.patch(
                "/bo/inventories/inputs",
                {
                    id,
                    merchantId,
                    merchantType,
                    costNew
                }
            );
            return data;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_INVENTORIES", parseError);
            throw parseError;
        }
    },
    async getInputInventoryById(
        { commit, dispatch },
        { id, merchantId, merchantType }
    ) {
        try {
            const { data } = await Http.instance.get(
                `/bo/inventories/inputs/${merchantType}/${merchantId}/${id}`
            );
            return data;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_INVENTORIES", parseError);
            throw parseError;
        }
    },
    async listInventoryByReference({ commit, dispatch }, { id }) {
        try {
            const { data } = await Http.instance.get(
                `/bo/inventories/references/${id}`
            );

            return data;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_INVENTORIES", parseError);
            throw parseError;
        }
    },
    async activeInventoryReference(
        { commit, dispatch },
        {
            isEnabled,
            merchantId,
            merchantType,
            priceAgreedMerchant,
            referenceId
        }
    ) {
        try {
            const { data } = await Http.instance.put("/bo/inventories/active", {
                referenceId,
                merchantId,
                merchantType,
                isEnabled,
                priceAgreedMerchant
            });
            return data;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_INVENTORIES", parseError);
            throw parseError;
        }
    },
    async deleteInventory(
        { commit, dispatch },
        { merchantId, merchantType, referenceId }
    ) {
        try {
            await Http.instance.delete("/bo/inventories", {
                data: {
                    referenceId,
                    merchantType,
                    merchantId
                }
            });
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_INVENTORIES", parseError);
            throw parseError;
        }
    }
};

export default actions;
