import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";

import actions from "./actions";
// Modules
import auth from "./auth";
import banners from "./banners";
import blogs from "./blogs";
import brands from "./brands";
import carts from "./carts";
import cashOutput from "./cash-outputs";
import categories from "./categories";
import commons from "./commons";
import control from "./control";
import customers from "./customers";
import files from "./files";
import getters from "./getters";
import images from "./images";
import inventories from "./inventories";
import lines from "./lines";
import logisticConfigurations from "./logistic-configurations";
import merchants from "./merchants";
import mutations from "./mutations";
import notifications from "./notifications";
import orders from "./orders";
import products from "./products";
import roles from "./roles";
import state from "./state";
import stores from "./stores";
import sublines from "./sublines";
import suppliers from "./suppliers";
import supplyLists from "./supply-lists";
import users from "./users";
import warehouses from "./warehouses";

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
    storage: window.localStorage,
    modules: ["auth", "control", "commons", "stores"]
});

export default new Vuex.Store({
    getters,
    mutations,
    state,
    actions,
    modules: {
        logisticConfigurations,
        notifications,
        inventories,
        supplyLists,
        warehouses,
        categories,
        cashOutput,
        merchants,
        suppliers,
        customers,
        sublines,
        products,
        commons,
        control,
        banners,
        images,
        brands,
        orders,
        files,
        blogs,
        stores,
        users,
        lines,
        roles,
        carts,
        auth
    },
    strict: false,
    plugins: [vuexLocal.plugin]
});
