import { handleErrorAxios } from "@/common/lib/formatter";
import Http from "@/common/lib/http";

const actions = {
    async createBanner(
        { commit, dispatch },
        { active, imageId, lineSlug, name, order, path }
    ) {
        try {
            const { data } = await Http.instance.post("/bo/banners", {
                imageId,
                lineSlug,
                name,
                path,
                order,
                active
            });

            return data;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_BANNERS", parseError);
            throw parseError;
        }
    },

    async listBannersPaginated(
        { commit, dispatch },
        { active, complete, keyword, lineSlug, page }
    ) {
        try {
            const { data } = await Http.instance.get("/bo/banners", {
                params: { page, keyword, active, lineSlug, complete }
            });

            return data;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_BANNERS", parseError);
            throw parseError;
        }
    },

    async getBannerById({ commit, dispatch }, { id }) {
        try {
            const { data } = await Http.instance.get(`/bo/banners/${id}`);
            return data;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_BANNERS", parseError);
            throw parseError;
        }
    },

    async updateBanner(
        { commit, dispatch },
        { active, id, imageId, lineSlug, name, order, path }
    ) {
        try {
            const { data } = await Http.instance.patch("/bo/banners", {
                id,
                imageId,
                lineSlug,
                name,
                path,
                order,
                active
            });

            return data;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_BANNERS", parseError);
            throw parseError;
        }
    },

    async deleteBanner({ commit, dispatch }, { bannerId }) {
        try {
            await Http.instance.delete(`/bo/banners/${bannerId}`);
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_BANNERS", parseError);
            throw parseError;
        }
    },

    async listHighlightBanners({ commit, dispatch }, { lineSlug }) {
        try {
            const { data } = await Http.instance.get("/bo/banners/highlight", {
                params: { lineSlug }
            });
            return data.items;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_BANNERS", parseError);
            throw parseError;
        }
    },

    async updateHighlightBanners(
        { commit, dispatch },
        { bannersIds, lineSlug }
    ) {
        try {
            const { data } = await Http.instance.put("/bo/banners/highlight/", {
                lineSlug,
                bannersIds
            });
            return data;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_BANNERS", parseError);
            throw parseError;
        }
    }
};

export default actions;
