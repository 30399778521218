import { handleErrorAxios } from "@/common/lib/formatter";
import Http from "@/common/lib/http";

const actions = {
    async listSuppliersPaginated({ commit, dispatch }, { complete, page }) {
        try {
            const { data } = await Http.instance.get("/bo/suppliers", {
                params: { complete, page }
            });
            return data;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_SUPPLIERS", parseError);
            throw parseError;
        }
    },
    async getSupplierById({ commit, dispatch }, { id }) {
        try {
            const { data } = await Http.instance.get(`/bo/suppliers/${id}`);
            return data;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_SUPPLIERS", parseError);
            throw parseError;
        }
    },
    async createSupplier(
        { commit, dispatch },
        { description, keywords, name, phone, shortDescription, slug, tin }
    ) {
        try {
            const { data } = await Http.instance.post("/bo/suppliers", {
                tin,
                name,
                slug,
                description,
                shortDescription,
                phone,
                keywords
            });
            return data;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_SUPPLIERS", parseError);
            throw parseError;
        }
    },
    async updateSupplier(
        { commit, dispatch },
        { description, id, keywords, name, phone, shortDescription, slug, tin }
    ) {
        try {
            const { data } = await Http.instance.patch("/bo/suppliers/", {
                id,
                tin,
                name,
                slug,
                description,
                shortDescription,
                phone,
                keywords
            });

            return data;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_SUPPLIERS", parseError);
            throw parseError;
        }
    },
    async updateSupplierActive({ commit, dispatch }, { active, id, weight }) {
        try {
            const { data } = await Http.instance.put("/bo/suppliers/active", {
                id,
                active,
                weight
            });
            return data;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_SUPPLIERS", parseError);
            throw parseError;
        }
    }
};

export default actions;
